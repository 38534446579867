import slick from "slick-carousel";
import fancybox from "@fancyapps/fancybox";
import {popover, tooltip} from "bootstrap";
import cookie from "jquery.cookie";

$(function(){ 
    /* popover
     *  
     */
    $(function () {
        $('[data-toggle="popover"]').popover()
    });
    
    /* FANCYBOX */
     $("a.fancybox").fancybox({
         loop : true,
         keyboard : true,
     });
    
    /* COOKIE */
    if($("#cookieInfo").length){
        var cookie = $.cookie("cookieinfo");
        if(cookie === '0'){
            $("#cookieInfo .cookieClose").on('click', function(){
                $.cookie("cookieinfo", '1', {path:'/', expires : 3650});
                $("#cookieInfo").animate({
                    'margin-top': - $("#cookieInfo").outerHeight(true)
                }, 800, function(){
                    $("#cookieInfo").remove();
                });

            })
        }
    }
    
    $(".navbar-toggler").on("click", function(){
        if($(this).hasClass("collapsed")) {
            $(this).removeClass("collapsed");
            $(".mainnav").addClass("hover");
        }else{
            $(this).addClass("collapsed");
            $(".mainnav").removeClass("hover");
        }
    })

    // mobile menu
    $("#openMenu").on("click", function () {
        $(".mobilenav").addClass("mobilenav--visible");
    });
    $("#closeMobileNav").on("click", function () {
        $(".mobilenav").removeClass("mobilenav--visible");
    });
    $(".mobilenav__link--sub-open").on("click", function (e) {
        e.preventDefault();
        $(this).parent().find(".mobilenav__sub").addClass("mobilenav__sub--visible");
    });
    $(".mobilenav__link--sub-close").on("click", function (e) {
        $(".mobilenav__sub").removeClass("mobilenav__sub--visible");
    });
    $(".mobilenav__link--sub2-open").on("click", function (e) {
        e.preventDefault();
        $(this).parent().find(".mobilenav__sub2").addClass("mobilenav__sub2--visible");
    });
    $(".mobilenav__link--sub2-close").on("click", function (e) {
        e.preventDefault();
        $(".mobilenav__sub2").removeClass("mobilenav__sub2--visible");
    });
    
});
